<template>
  <div class="col-lg-6">
    <div class="card">
      <div class="card-header">
        <div class="vb__utils__heading mb-0">
          <strong>Счета</strong>
        </div>
      </div>
      <div class="card-body">
        <div class="text-center my-5" v-if="loading">
          <loading-outlined></loading-outlined>
        </div>
        <LineChart v-else v-bind="lineChartProps" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { LoadingOutlined } from "@ant-design/icons-vue";
import { LineChart, useLineChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import apiClient from "@/services/axios";
import { ref, computed } from 'vue'

Chart.register(...registerables);

const props = defineProps({
  date: {
    type: Array,
    default: ref([]),
  },
})
let
  loading = ref(true),
  data = ref([]),
  labels = ref([]);

const getData = () => {
  let from = '',
    to = '';

  if (props.date.length) {
    from = props.date[0].format('YYYY-MM-DD')
    to = props.date[1].format('YYYY-MM-DD')
    // let diff = moment.duration(props.date[1].diff(props.date[0])).days()
  }

  loading.value = true
  apiClient.get(`dashboard/chart/line/orders?from=${from}&to=${to}`).then(({data:response}) => {
    if (response.data) {
      data.value = response.data.data
      labels.value = response.data.labels
      loading.value = false
    }
  }).catch(() => {
    // console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const chartData = computed(() => {
  return {
    labels: labels.value,
    datasets: [
      {
        label: "Заказы",
        data: data.value,
        fill: true,
        borderColor: "#4b7cf3",
        backgroundColor: "#d1deff",
        lineTension: 0.3,
      },
    ],
  }
});
const options = computed(() => ({
  plugins: {
    legend: {
      display: false,
    },
  },
}));
const { lineChartProps } = useLineChart({
  options,
  chartData: chartData,
});

getData()

</script>
